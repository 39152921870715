
import { Component, Mixins, Watch } from 'vue-property-decorator';
import Page from '@/views/Page.vue';
import { AppHostnameMixin, AthleteApiMixin, AuthMixin, StringsMixin, VuetifyMixin, PaginatedTableMixin, RoutingMixin } from '@/mixins';
import { BAEventModel } from '@/models/baEvent';
import { RecruitingProfileModel } from '@/models/athlete/RecruitingProfileModel';
import { baEventsStore } from '@/store';
import { DataTableHeader } from 'vuetify';
import { athleteApi } from '@/api/AthleteApi';
import { TeamModel } from '@/models/team';
import { getEnumValues } from '@/../types/enums/get-enum-values';
import ChipGroup from '@/components/forms/ChipGroup.vue';
import { AgeGroup, AgeGroupValues, DominantSide, DominantSideValues } from '@best-athletes/ba-types';

enum SoccerPositionShort {
	GK = "GK",
	DF = "DF",
	MF = "MF",
	FW = "FW",
}

interface GroupInfo {
    label: string;
    partial: boolean;
    visible: boolean;
}
interface HeaderInfo {
    text: string;
    value: string;
    label: string;
    visible: boolean;
    group: string;
}

@Component({
	components: {
		Page,
        ChipGroup,
	}
})
export default class BAShowcaseEventPage extends Mixins(VuetifyMixin, AppHostnameMixin, StringsMixin, AuthMixin, AthleteApiMixin, PaginatedTableMixin, RoutingMixin){
    showcaseEvent: BAEventModel = undefined;
    eventLoaded: boolean = false;
    filterPanels = [];
    search: string = '';

    get eventId(): string {        
        return this.$route.params.eventId;
    }

    mounted() {
        this.tableOptions.itemsPerPage = this.IsMobile? 10 : 25;
        this.tableOptions.sortBy = ['number'];
        this.tableOptions.mustSort = true;
    }

    @Watch('$route.params.eventId') 
    async updateEvent() {
        this.eventLoaded = false;
		await this.loadTable();
	}
    numParticipants = 0;
    async loadTable(): Promise<void> {
        if( !this.eventLoaded ) this.showcaseEvent = await baEventsStore.findEvent(this.eventId);
        this.loadParticipants();
        this.eventLoaded = true;
    }
	get PageLoading(): boolean {
		return !this.eventLoaded;
	}
	get TableLoading(): boolean {
		return this.PageLoading || !this.participantsLoaded;
	}
	get TotalItems(): number {
		return this.numParticipants;
	}
    tableAction: string = '';
    get TableAction(): string {
        return this.tableAction;
    }
  
    headerData: HeaderInfo[] = [
            {text: 'Name', value: 'name', label: 'Name', visible: true, group: 'required'},
            {text: 'Team', value: 'team', label: 'Team', visible: true, group: 'required'},
            {text: 'Num', value: 'number', label: 'Number', visible: true, group: 'Profile'},
            {text: 'Pos', value: 'position', label: 'Position', visible: true, group: 'Profile'},
            {text: 'Age', value: 'age', label: 'Age Group', visible: true, group: 'Profile'},
            {text: 'DFt', value: 'dominantFoot', label: 'Dominant Foot', visible: true, group: 'Profile'},
            {text: 'Acl', value: 'physical-acceleration', label: 'Acceleration (10m)', visible: true, group: 'Physical'},
            {text: 'Spd', value: 'physical-speed', label: 'Speed (20-35m)', visible: true, group: 'Physical'},
            {text: 'Agl', value: 'physical-agility', label: 'Agility', visible: true, group: 'Physical'},
            {text: 'Pow', value: 'physical-power', label: 'Power', visible: true, group: 'Physical'},
            {text: 'Rcv', value: 'physical-recovery', label: 'Recovery (YoYo)', visible: true, group: 'Physical'},
            {text: 'Tech', value: 'scouting-technical', label: 'Technical', visible: true, group: 'Scouting'},
            {text: 'Tact', value: 'scouting-tactical', label: 'Tactical', visible: true, group: 'Scouting'},
            {text: 'Phys', value: 'scouting-physical', label: 'Physical', visible: true, group: 'Scouting'},
            {text: 'Ment', value: 'scouting-mental', label: 'Mental', visible: true, group: 'Scouting'},
            {text: 'M.Tgh', value: 'mental-toughness', label: 'Mental Toughness', visible: true, group: 'Mindset'},
            {text: 'M.Exc', value: 'mental-execution', label: 'Mental Execution', visible: true, group: 'Mindset'},
            {text: 'Coach', value: 'mental-coachability', label: 'Coachability', visible: true, group: 'Mindset'},
        ];
    get HeaderGroups(): GroupInfo[] {
        const groups = this.headerData.map(h => h.group);
        const uniqueGroups = groups.filter((value, index, array) => ( array.indexOf(value) === index ) && ( value != 'required'));
        return uniqueGroups.map(g => {
            const groupHeaderData = this.headerData.filter(h => h.group === g);
            return {
                label: g,
                partial: !!groupHeaderData.find(item => item.visible) && !!groupHeaderData.find(item => !item.visible),
                visible: groupHeaderData.every(item => item.visible)
            }
        });
    }
    get ParticipantHeaders(): DataTableHeader<any>[] {
        let headers: DataTableHeader<any>[] = [];
        for( const h of this.headerData ) {
            if( h.visible ) headers.push({text: h.text, value: h.value});
        }
        headers.push({text: '', width:"50", value: 'actions', sortable: false})
        return headers;
    }
    onClickColumnGroup(group: GroupInfo) {
        for( const h of this.headerData ) {
            if( h.group === group.label ) {
                h.visible = group.visible;
            }
        }
        this.updateTable();
    }
    onClickColumnItem(item: HeaderInfo) {
        this.updateTable();
    }

    allParticipants: Array<RecruitingProfileModel|void> = [];       // cached participants
    participants: RecruitingProfileModel[] = [];                    // participants in view
    pageParticipants: RecruitingProfileModel[] = [];                    // participants in view
    participantsLoaded = false;
    firstTime = true;
    async loadParticipants() {
        this.participantsLoaded = false;
        this.viewParticipants = false;
        this.tableAction = "Loading";
        this.participants = [];
        this.numParticipants = 0;
        for(const participant of this.showcaseEvent.participants ) {
            var recruitingProfile: RecruitingProfileModel | void;
            if( this.firstTime ) {
                // find athlete in the database and cache
                recruitingProfile = await this.findAthleteRecruitingProfile(participant.attendeeId);
            } else {
                // is the athlete in the cache
                recruitingProfile = this.allParticipants.find(p => {
                    if( !p || !p.athleteProfile ) return false; 
                    return( p.athleteProfile?.id === participant.attendeeId );
                });
                if( !recruitingProfile ) {
                    // athlete not previously cached, find them in the database
                    recruitingProfile = await this.findAthleteRecruitingProfile(participant.attendeeId);
                }
            }
            if( !recruitingProfile) continue;

            // filter by the search box
            if( this.search ) {
                if( !recruitingProfile.RecruitingFullName.toLowerCase().includes(this.search.toLowerCase()) ) continue;
            }
            // filter by position
            if( this.IsNotEmptyArray(this.positionFilter) ) {
                const playerPositions = recruitingProfile.RecruitingPosition();
                if( this.IsEmpty(playerPositions) ) continue;
                if( !this.positionFilter.some(p => playerPositions.includes(p)) ) continue;
            }
            // filter by age
            if( this.IsNotEmptyArray(this.ageFilter) ) {
                const ageGroup = recruitingProfile.RecruitingAgeGroup;
                if( this.IsEmpty(ageGroup) ) continue;
                if( !this.ageFilter.some(p => ageGroup.includes(p)) ) continue;
            }
            // filter by dominant side
            if( this.IsNotEmptyArray(this.dominantSideFilter) ) {
                const dominantSides = recruitingProfile.RecruitingDominantSide;
                if( this.IsEmpty(dominantSides) ) continue;
                if( !this.dominantSideFilter.some(p => dominantSides.includes(p)) ) continue;
            }
            // filter by physical assessment
            const physicalPerformance = recruitingProfile.physicalPerformance;
            if( this.filterBySpeed ) {
                if( !physicalPerformance ) continue;
                if( this.IsEmpty( physicalPerformance.twentyToThirtyFiveMeterSplit ) ) continue;
                if( physicalPerformance.twentyToThirtyFiveMeterSplit < this.trueValue(this.speedFilter[0], this.speedBoundries) ) continue;
                if( physicalPerformance.twentyToThirtyFiveMeterSplit > this.trueValue(this.speedFilter[1], this.speedBoundries) ) continue;
            }
            if( this.filterByAccel ) {
                if( !physicalPerformance ) continue;
                if( this.IsEmpty( physicalPerformance.tenMeterSprint ) ) continue;
                if( physicalPerformance.tenMeterSprint < this.trueValue(this.accelFilter[0], this.accelBoundries) ) continue;
                if( physicalPerformance.tenMeterSprint > this.trueValue(this.accelFilter[1], this.accelBoundries) ) continue;
            }
            if( this.filterByAgility ) {
                if( !physicalPerformance ) continue;
                if( this.IsEmpty( physicalPerformance.reactiveStrengthIndex ) ) continue;
                if( physicalPerformance.reactiveStrengthIndex < this.trueValue(this.agilityFilter[0], this.agilityBoundries) ) continue;
                if( physicalPerformance.reactiveStrengthIndex > this.trueValue(this.agilityFilter[1], this.agilityBoundries) ) continue;
            }
            if( this.filterByPower ) {
                if( !physicalPerformance ) continue;
                if( this.IsEmpty( physicalPerformance.counterMovementJumpHeight) ) continue;
                if( physicalPerformance.counterMovementJumpHeight < this.trueValue(this.powerFilter[0], this.powerBoundries) ) continue;
                if( physicalPerformance.counterMovementJumpHeight > this.trueValue(this.powerFilter[1], this.powerBoundries) ) continue;
            }
            if( this.filterByRecovery ) {
                if( !physicalPerformance ) continue;
                if( this.IsEmpty( physicalPerformance.yoyoIntermittentRecoveryTestStage) ) continue;
                if( physicalPerformance.yoyoIntermittentRecoveryTestStage < this.trueValue(this.recoveryFilter[0], this.recoveryBoundries) ) continue;
                if( physicalPerformance.yoyoIntermittentRecoveryTestStage > this.trueValue(this.recoveryFilter[1], this.recoveryBoundries) ) continue;
            }
            // filter by scouting report
            const scoutingReport = recruitingProfile.scoutingAverage;
            if( this.filterByScoutTechnical ) {
                if( !scoutingReport ) continue;
                if( this.IsEmpty( scoutingReport.technical ) ) continue;
                if( scoutingReport.technical < this.scoutTechnicalFilter[0] ) continue;
                if( scoutingReport.technical > this.scoutTechnicalFilter[1] ) continue;
            }
            if( this.filterByScoutTactical ) {
                if( !scoutingReport ) continue;
                if( this.IsEmpty( scoutingReport.tactical ) ) continue;
                if( scoutingReport.tactical < this.scoutTacticalFilter[0] ) continue;
                if( scoutingReport.tactical > this.scoutTacticalFilter[1] ) continue;
            }
            if( this.filterByScoutPhysical ) {
                if( !scoutingReport ) continue;
                if( this.IsEmpty( scoutingReport.physical ) ) continue;
                if( scoutingReport.physical < this.scoutPhysicalFilter[0] ) continue;
                if( scoutingReport.physical > this.scoutPhysicalFilter[1] ) continue;
            }
            if( this.filterByScoutMental ) {
                if( !scoutingReport ) continue;
                if( this.IsEmpty( scoutingReport.mental ) ) continue;
                if( scoutingReport.mental < this.scoutMentalFilter[0] ) continue;
                if( scoutingReport.mental > this.scoutMentalFilter[1] ) continue;
            }
            // filter by mindset
            const mindsetReport = recruitingProfile.mindsetReport;
            if( this.filterByMindsetMentalToughness ) {
                if( !mindsetReport ) continue;
                if( this.IsEmpty( mindsetReport.mentalToughness ) ) continue;
                if( mindsetReport.mentalToughness < this.mindsetMentalToughnessFilter[0] ) continue;
                if( mindsetReport.mentalToughness > this.mindsetMentalToughnessFilter[1] ) continue;
            }
            if( this.filterByMindsetMentalExecution ) {
                if( !mindsetReport ) continue;
                if( this.IsEmpty( mindsetReport.mentalExecution ) ) continue;
                if( mindsetReport.mentalExecution < this.mindsetMentalExecutionFilter[0] ) continue;
                if( mindsetReport.mentalExecution > this.mindsetMentalExecutionFilter[1] ) continue;
            }
            if( this.filterByMindsetCoachability ) {
                if( !mindsetReport ) continue;
                if( this.IsEmpty( mindsetReport.coachability ) ) continue;
                if( mindsetReport.coachability < this.mindsetCoachabilityFilter[0] ) continue;
                if( mindsetReport.coachability > this.mindsetCoachabilityFilter[1] ) continue;
            }

            // athlete meets all the filter criteria
            this.participants.push(recruitingProfile);
            this.numParticipants = this.participants.length;
        }
        this.numParticipants = this.participants.length;
        const start = (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage;
        const end = start + this.tableOptions.itemsPerPage;
        this.pageParticipants = this.participants.slice(start, end);
        this.computeBoundries();
        this.sortParticipants();
        this.tableAction = "Found";
        this.viewParticipants = true;
        this.firstTime = false;
        this.participantsLoaded = true;
        return;
    }
    async findAthleteRecruitingProfile(athleteId: string) {
        const recruitingProfile = await athleteApi.getAthleteRecruitingProfile(athleteId, {as : 'coach'}).catch(e => {});
        if( !!recruitingProfile ) this.allParticipants.push(recruitingProfile);
        return recruitingProfile;
    }

    strSort(a: string, b: string, sortDesc): number {
        return a.localeCompare(b) * (sortDesc? -1 : 1)
    }
    posSort(a: string, b: string, sortDesc): number {
        let aVal = 0;
        let bVal = 0;
        if( a.toLowerCase().startsWith('g') ) aVal = 1;
        else if( a.toLowerCase().startsWith('d') ) aVal = 3;
        else if( a.toLowerCase().startsWith('m') ) aVal = 6;
        else if( a.toLowerCase().startsWith('f') ) aVal = 9;
        if( b.toLowerCase().startsWith('g') ) bVal = 1;
        else if( b.toLowerCase().startsWith('d') ) bVal = 3;
        else if( b.toLowerCase().startsWith('m') ) bVal = 6;
        else if( b.toLowerCase().startsWith('f') ) bVal = 9;

        return ( aVal - bVal ) * (sortDesc? -1 : 1);
    }
    numSort(a: string, b: string, sortDesc): number {
        if( this.IsEmpty(a) ) {
            return this.IsEmpty(b)? 0 : 1;
        } else if( this.IsEmpty(b) ) {
            return -1;
        }
        return ( Number(a) - Number(b) ) * (sortDesc? -1 : 1);
    }
    sortParticipants() {
        if( this.numParticipants === 0 ) return;

        this.participants.sort((a,b) => {
            if( this.tableOptions.sortBy.length === 0 ) {
                this.tableOptions.sortBy.push('number');
                this.tableOptions.sortDesc.push(false);
            }
            const sortBy = this.tableOptions.sortBy[0];
            const sortDesc = this.tableOptions.sortDesc[0];
            switch( sortBy ) {
                case 'name':
                    return this.strSort(a.RecruitingFullName, b.RecruitingFullName, sortDesc);
                case 'team':
                    return this.strSort(a.RecruitingTeamName(), b.RecruitingTeamName(), sortDesc);
                case 'number':
                    return this.numSort(a.RecruitingNumber(), b.RecruitingNumber(), sortDesc);
                case 'position':
                    return this.posSort(a.RecruitingPosition(), b.RecruitingPosition(), sortDesc);
                case 'physical-speed':
                    return this.numSort(a.RecruitingSpeed, b.RecruitingSpeed, sortDesc);
                case 'physical-acceleration':
                    return this.numSort(a.RecruitingAcceleration, b.RecruitingAcceleration, sortDesc);
                case 'physical-agility':
                    return this.numSort(a.RecruitingAgility, b.RecruitingAgility, !sortDesc);
                case 'physical-power':
                    return this.numSort(a.RecruitingPower, b.RecruitingPower, !sortDesc);
                case 'physical-recovery':
                    return this.numSort(a.RecruitingRecovery, b.RecruitingRecovery, !sortDesc);
                case 'scouting-technical':
                    return this.numSort(a.RecruitingTechnical, b.RecruitingTechnical, !sortDesc);
                case 'scouting-tactical':
                    return this.numSort(a.RecruitingTactical, b.RecruitingTactical, !sortDesc);
                case 'scouting-physical':
                    return this.numSort(a.RecruitingPhysical, b.RecruitingPhysical, !sortDesc);
                case 'scouting-mental':
                    return this.numSort(a.RecruitingMental, b.RecruitingMental, !sortDesc);
                case 'mental-toughness':
                    return this.numSort(a.RecruitingMentalToughness, b.RecruitingMentalToughness, !sortDesc);
                case 'mental-execution':
                    return this.numSort(a.RecruitingMentalExecution, b.RecruitingMentalExecution, !sortDesc);
                case 'mental-coachability':
                    return this.numSort(a.RecruitingCoachability, b.RecruitingCoachability, !sortDesc);
            }
            return  0;
        })
    }
    viewParticipants: boolean = false;
    async onToggleViewParticipants() {
        this.viewParticipants = !this.viewParticipants;
    }

    viewFilterPhysical: boolean = false;
    async onToggleViewFilterPhysical() {
        this.viewFilterPhysical = !this.viewFilterPhysical;
    }
    viewFilterScouting: boolean = false;
    async onToggleviewFilterScouting() {
        this.viewFilterScouting = !this.viewFilterScouting;
    }
    viewFilterMindset: boolean = false;
    async onToggleviewFilterMindset() {
        this.viewFilterMindset = !this.viewFilterMindset;
    }

    TeamPublicSharingUrl(team: TeamModel): string {
        if( !team ) return '';
		return `https://${this.PublicAppUrl}/team/${team.id}/${team.sharingUrlId}`
	}
	AthletePublicSharingUrl(recruitingProfile: RecruitingProfileModel ): string {
		return `https://${this.PublicAppUrl}/athlete/${recruitingProfile.athleteProfile.id}/${recruitingProfile.athleteProfile.sharingUrlId}`
	}

    positionFilter: SoccerPositionShort[] = [];
    get PositionsList(): SoccerPositionShort[] {
        return getEnumValues<SoccerPositionShort>(SoccerPositionShort);
    }
    ageFilter: AgeGroup[] = [];
	get AgeGroupValues(): AgeGroup[] {
		return AgeGroupValues;
	}
    dominantSideFilter: DominantSide[] = [];
    get DominantSideValues(): DominantSide[] {
        return DominantSideValues;
    }

    filterBySpeed: boolean = false;
    speedFilter: number[] = [0, 100];
    speedFilterHL: number[] = [0, 100];
    speedBoundries: Boundries;
    filterByAccel: boolean = false;
    accelFilter: number[] = [0, 100];
    accelFilterHL: number[] = [0, 100];
    accelBoundries: Boundries;
    filterByAgility: boolean = false;
    agilityFilter: number[] = [0, 100];
    agilityBoundries: Boundries;
    filterByPower: boolean = false;
    powerFilter: number[] = [0, 100];
    powerBoundries: Boundries;
    filterByRecovery: boolean = false;
    recoveryFilter: number[] = [0, 100];
    recoveryBoundries: Boundries;
    filterByScoutTechnical: boolean = false;
    scoutTechnicalFilter: number[] = [1, 5];
    filterByScoutTactical: boolean = false;
    scoutTacticalFilter: number[] = [1, 5];
    filterByScoutPhysical: boolean = false;
    scoutPhysicalFilter: number[] = [1, 5];
    filterByScoutMental: boolean = false;
    scoutMentalFilter: number[] = [1, 5];
    filterByMindsetMentalToughness: boolean = false;
    mindsetMentalToughnessFilter: number[] = [1, 100];
    filterByMindsetMentalExecution: boolean = false;
    mindsetMentalExecutionFilter: number[] = [1, 100];
    filterByMindsetCoachability: boolean = false;
    mindsetCoachabilityFilter: number[] = [1, 100];

    private minmax(strValues: string[]): Boundries {
        const validNumbers: number[] = strValues.filter(str => str.trim() !== '').map(str => parseFloat(str));
        if( validNumbers.length === 0 ) return new Boundries(0, 0);

        const min = Math.min(...validNumbers);
        const max = Math.max(...validNumbers);
        return new Boundries(min, max);
    }
    computeBoundries() {
        this.speedBoundries = this.minmax(this.allParticipants.map(p => p? p.RecruitingSpeed : ''));
        this.accelBoundries = this.minmax(this.allParticipants.map(p => p? p.RecruitingAcceleration : ''));
        this.agilityBoundries = this.minmax(this.allParticipants.map(p => p? p.RecruitingAgility : ''));
        this.powerBoundries = this.minmax(this.allParticipants.map(p => p? p.RecruitingPower : ''));
        this.recoveryBoundries = this.minmax(this.allParticipants.map(p => p? p.RecruitingRecovery : ''));
    }
    trueValue(value: number, boundries: Boundries): number {
        return( boundries.min + value * boundries.step );
    }
    trueValueStr(value: number, boundries: Boundries, unit: string = '', precision: number = 2): string {
        return `${this.trueValue(value, boundries).toFixed(precision)} ${unit}`;
    }
    scoutValueStr(value: number) {
        if( value > 4.9 ) return '4+';
        return value.toFixed(0);
    }

    speedInput(value) {
        this.speedFilter = [100 - value[1], 100 - value[0]];
        this.updateFilter();       
    }
    accelInput(value) {
        this.accelFilter = [100 - value[1], 100 - value[0]];
        this.updateFilter();       
    }

    get HasFilters(): boolean {
        return( this.IsNotEmptyArray( this.positionFilter ) ||
            this.IsNotEmptyArray( this.ageFilter ) ||
            this.IsNotEmptyArray( this.dominantSideFilter ) ||
            this.filterBySpeed || this.filterByAccel || this.filterByAgility || this.filterByPower || this.filterByRecovery
        )
    }
    onResetFilters() {
        this.search = '';
        this.positionFilter = [];
        this.ageFilter = [];
        this.dominantSideFilter = [];
        this.filterBySpeed = false;
        this.filterByAccel = false;
        this.filterByAgility = false;
        this.filterByPower = false;
        this.filterByRecovery = false;
        this.updateFilter();
    }
}

class Boundries {
    min: number
    max: number

    constructor(min, max) {
        this.min = min;
        this.max = max;
    }

    get defined(): boolean { return ( this.max > 0 ) || ( this.min < this.max ) };
    get range(): number { return this.max - this.min};
    get step(): number { return this.range / 100};
}
