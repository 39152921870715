
import { Component, Mixins, Prop } from "vue-property-decorator";
import { VuetifyMixin, MyCoachMixin, StringsMixin, BAIconsMixin, AthleteApiMixin, SportsSelectorMixin, ScoutingReportScoreSelectorMixin, CoachRoutingMixin } from "@/mixins";
import { AthleteReportModelEx, AthleteReportCategoryHeading } from "@/models/athleteReport/AthleteReportModel";
import { AthleteAutocompleteInfo } from '@best-athletes/ba-types';
import AthleteAutocomplete from "@/components/forms/AthleteAutocomplete.vue";
import { athleteReportApi } from "@/api/AthleteReportApi"
import { PlayerTypeValues } from '@/../types/enums'
import ScoreSelector from '@/components/forms/ScoreSelector.vue';
import { notificationStore } from "@/store";


@Component({
	components: {
		AthleteAutocomplete,
		ScoreSelector,
	}
})
export default class CoachAthleteReport extends Mixins(
		VuetifyMixin,
		StringsMixin,
		BAIconsMixin,
		MyCoachMixin,
		AthleteApiMixin,
		SportsSelectorMixin,
		ScoutingReportScoreSelectorMixin,
		CoachRoutingMixin,
	){
	@Prop() reportId: string;
	@Prop() athleteId: string;
	@Prop() teamId: string;
	@Prop() coachId: string;

	PlayerTypeValues = PlayerTypeValues;

	athleteLink: AthleteAutocompleteInfo;
	athleteReport: AthleteReportModelEx;
	isReportUpdating = false;

	isReportLoading: boolean = false;
	isReportLoaded: boolean = false;
	isReportChanging: boolean = false;
	isReportChanged: boolean = false;

	isAthleteReportLoaded = false;
	async mounted() {
		await this.initializeReport();
		await this.loadReport();
	}

	async initializeReport() {
		if( this.IsEmpty(this.coachId) ) this.coachId = this.MyCoachId;

		this.isAthleteReportLoaded = false;
		this.athleteReport = new AthleteReportModelEx(this.athleteId, this.teamId, this.coachId);
		await this.athleteReport.initialize();
		this.isAthleteReportLoaded = true;
	}
	async loadReport() {
		this.isReportLoaded = false;
		this.isReportLoading = true;
		if( this.IsSavedReport ) {
			this.isAthleteReportLoaded = false;
			const foundReport = await athleteReportApi.findById( this.reportId );
			this.athleteReport = new AthleteReportModelEx().load(foundReport);
			await this.athleteReport.loadAthleteCoach();
			await this.athleteReport.loadAthlete();
			this.isAthleteReportLoaded = true;
		}
		else if( this.isAthleteReportLoaded ) {
			await this.athleteReport.updateReport(this.athleteId, this.teamId, this.coachId);
		}
		this.athleteId = this.athleteReport?.athleteId;
		this.teamId = this.athleteReport?.teamId;
		this.coachId = this.athleteReport?.coachId;
		
		this.isReportLoading = false;
		this.isReportLoaded = true;
	}

	get DebugInfo() {
		return {a: this.AthleteName, c: this.CoachName, t: this.TeamName};
	}
	isEditingReport: boolean = false;
	get IsSavedReport(): boolean {
		// if a report id is provided, the report was previously saved
		return this.IsNotEmpty( this.reportId );
	}
	get IsLoading(): boolean {
		// in process of loading some information from back-end
		return this.isReportLoading || this.isReportUpdating;
	}
	get IsAthleteLinked(): boolean {
		// if an athlete id is provided, athlete is linked to report
		return this.IsNotEmpty( this.athleteId );
	}
	get IsAthleteLoaded(): boolean {
		if( !this.isAthleteReportLoaded ) return false;
		return this.athleteReport.HasAthleteProfile;
	}
	get AthleteName(): string {
		if( !this.isAthleteReportLoaded ) return this.athleteId;
		return this.athleteReport.AthleteName;
	}
	get CoachName(): string {
		if( !this.isAthleteReportLoaded ) return this.coachId;
		return this.athleteReport.CoachName;
	}
	get TeamName(): string {
		if( !this.isAthleteReportLoaded ) return this.teamId;
		return this.athleteReport.TeamName;
	}

	async resetAthleteLink() {
		this.athleteId = undefined;
		this.teamId = undefined;
	}
	async onChangeAthleteLink(athleteLink: AthleteAutocompleteInfo) {
		this.isReportUpdating = true;

		if( this.IsNotEmpty(this.athleteLink) && this.IsEmpty(athleteLink.id) ) {
			this.athleteLink.id = undefined;
		}
		await this.resetAthleteLink();

		this.athleteId = athleteLink.id;
		await this.initializeReport();
		await this.athleteReport.updateReport(this.athleteId, this.teamId, this.coachId);

		this.isReportUpdating = false;
	}
	async onChangeJerseyNumber(number: string) {
		this.onReportChanged();
	}
	async onChangePosition(position: string) {
		this.onReportChanged();
	}
	async onChangeScore() {
		this.onReportChanged();
	}
	async onReportChanged() {
		this.isReportChanging = true;
		this.isReportChanged = true;
		this.isReportChanging = false;
	}

	async onExpandHeading(heading: AthleteReportCategoryHeading) {
		heading.expanded = !heading.expanded;
		this.onReportChanged();
	}
	async onNewAthleteReport() {
		await this.gotoCoachAthleteReport();
		await this.resetAthleteLink();
		await this.initializeReport();
	}
	async onCancel() {
		if( this.isReportChanged ) {
			if( !confirm(`Are you sure you want to exit this form? Your changes will not be saved.`) ) return;
		}

		this.gotoCoachAthleteReportsSummary();
	}
	async onSave() {
		if( !this.isReportChanged ) return;

		this.isAthleteReportLoaded = false;
		this.athleteReport = new AthleteReportModelEx().load(this.athleteReport);
		this.isAthleteReportLoaded = true;
		if( this.IsEmpty(this.athleteReport.teamId) ) this.athleteReport.teamId = this.athleteReport.TeamName;
		this.athleteReport.tags = `${this.athleteReport.athlete.FullName}, ${this.athleteReport.TeamName}`;
		await athleteReportApi.save(this.athleteReport);

		notificationStore.pushSnackbarSuccess({message: 'Athlete Report saved successfully'});
		this.gotoCoachAthleteReportsSummary();
	}
}
